import "./CreateQuizComponent.scss";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuizDrawerComponent from "../quizDrawerComponent/QuizDrawerComponent";
import { SelectSlideComponent } from "../selectSlideComponent/SelectSlideComponent";
import CreateMCQComponent from "../createMCQComponent/CreateMCQComponent";
import CreateNonQuestionComponent from "../createNonQuestionComponent/CreateNonQuestionComponent";
import { BEURL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import Context from "../../../context/Context";
import axios from "axios";
import CreateMTFComponent from "../createMTFComponent/CreateMTFComponent";
import CreateDNDComponent from "../createDNDComponent/CreateDNDComponent";
import CreateFIBComponent from "../createFIBComponent/CreateFIBComponent";
import AccessPromptComponent from "../accessPromptComponent/AccessPromptComponent";
import { get_answer_blanks_with_hint_letter } from "../../../shared/constants";

const CreateQuizComponent = () => {
  const {
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    questionData,
    setLoadingDisplayFlag,
    editQuestionsList,
    setEditQuestionsList,
    editQuizDetails,
    setEditQuizDetails,
  } = useContext(Context);
  const { quizid } = useParams();

  const [componentInFocus, setComponentInFocus] = useState("");
  const [hasEditAccess, setHasEditAccess] = useState(true);

  const navigate = useNavigate();

  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  useEffect(() => {
    if (!editQuizDetails.quizId) { // If quiz is not loaded already fetch details
      setLoadingDisplayFlag(true);
      axios
        .post(BEURL + apiEndpoints.getQuizDetails, { quizId: quizid })
        .then((res) => {
          let curQuizDetails = JSON.parse(JSON.stringify(res.data)); // Deep copy the result data object
          delete curQuizDetails.questions; // Remove questions
          setEditQuizDetails(curQuizDetails);
          let prepareEditQuestionsList = res.data.questions;
          prepareEditQuestionsList = prepareEditQuestionsList.map(
            (question, index) => {
              // Parse correctAnswer and answerChoices
              question.correctAnswer = JSON.parse(
                question.correctAnswer.replace(/'/g, `"`)
              );
              question.answerChoices = JSON.parse(
                question.answerChoices.replace(/'/g, `"`)
              );

              // FIB and CNT questions need answer blanks
              if (["FIB", "CNT"].includes(question.questionType)) {
                // Get first answer of the array
                question.answerBlanks = get_answer_blanks_with_hint_letter(
                  question.correctAnswer[0]
                );
              }
              return question;
            }
          );

          setEditQuestionsList(prepareEditQuestionsList);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            //no authorization
            if (
              err.response.data ===
              "User not authorized to view and/or edit this quiz"
            )
              setHasEditAccess(false);
          } else if (err.response.status === 404) {
            // quiz or question not found
            setLoadingDisplayFlag(true);
            setAlert("error", err.response.data);
            setTimeout(() => {
              setAlert("info", "Redirecting you to home");
            }, 2.5 * 1000);
            setTimeout(() => {
              setLoadingDisplayFlag(false);
              navigate("/home");
            }, 5 * 1000);
          } else alert(err.response.data);
        })
        .finally(() => {
          setLoadingDisplayFlag(false);
        });
    }
  }, []);

  //Scroll to last or active minislide
  useEffect(() => {
    let activeSlideList = document.getElementsByClassName("miniSlide__active");
    let lastActiveSlide = null;
    if (activeSlideList.length > 0)
      lastActiveSlide = activeSlideList[activeSlideList.length - 1];
    if (!!lastActiveSlide) lastActiveSlide.scrollIntoView({block: "nearest"});
  });

  useEffect(() => {
    if (
      Object.keys(questionData).length === 0 ||
      questionData.questionId == "activeSlide"
    )
      //initial load or active slide
      setComponentInFocus("SelectSlideComponent");
    else {
      let allowedQuestionTypes = [
        "MCQ",
        "TRF",
        "MTF",
        "DND",
        "FIB",
        "CNT",
        "NQS",
      ];
      let questionType = questionData.questionType;
      if (allowedQuestionTypes.includes(questionType))
        setComponentInFocus(questionType);
      else alert("Invalid question Type");
    }
  }, [questionData]);

  return !hasEditAccess ? (
    <AccessPromptComponent open={!hasEditAccess} />
  ) : (
    <div className="createquiz">
      <QuizDrawerComponent setComponentInFocus={setComponentInFocus} componentInFocus={componentInFocus} />
      <div className="createquiz__main">
        {componentInFocus === "SelectSlideComponent" ? (
          <SelectSlideComponent setComponentInFocus={setComponentInFocus} />
        ) : componentInFocus === "MCQ" || componentInFocus === "TRF" ? (
          <CreateMCQComponent
            quizid={quizid}
            questionType={componentInFocus}
            setComponentInFocus={setComponentInFocus}
            questionData={questionData}
          />
        ) : componentInFocus === "MTF" ? (
          <CreateMTFComponent
            quizid={quizid}
            setComponentInFocus={setComponentInFocus}
            questionData={questionData}
          />
        ) : componentInFocus === "DND" ? (
          <CreateDNDComponent
            quizid={quizid}
            setComponentInFocus={setComponentInFocus}
            questionData={questionData}
          />
        ) : componentInFocus === "FIB" || componentInFocus === "CNT" ? (
          <CreateFIBComponent
            quizid={quizid}
            questionType={componentInFocus}
            setComponentInFocus={setComponentInFocus}
            questionData={questionData}
          />
        ) : componentInFocus === "NQS" ? (
          <CreateNonQuestionComponent
            setComponentInFocus={setComponentInFocus}
            questionData={questionData}
            quizid={quizid}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CreateQuizComponent;
